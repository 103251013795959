import  { Fragment } from 'react';
import PropTypes from 'prop-types';

import TopicContent from 'core/components/TopicContent';
import withTheme from 'core/hocs/withTheme';
import withBreakpoint from 'core/hocs/withBreakpoint';

import resolveRelationships from 'core/utils/relationships';

import { getRealType } from 'site/utils';

import {
  Content1,
  Content2,
  Content3,
  Content4,
} from 'site/components/Ads/mobile';

import coreImage from 'core/components/TopicContent/blocks/image';

import Opinion from 'site/vikonts/Opinion';
import Incut from 'site/vikonts/Incut';
import ReadMore from 'site/vikonts/ReadMore';
import Recipe from 'site/vikonts/Recipe';
import PhotoGallery from 'site/vikonts/PhotoGallery';
import Markdown from 'site/vikonts/Markdown';

const relationships = resolveRelationships(['content'], {});

const mobileAds = [
  Content1,
  Content2,
  Content3,
  Content4,
].map((renderer, index, ads) => ({
  before: 650,
  after: 500,
  showOnlyOnce: ads.length !== index + 1,
  renderer: renderer,
}));


function SiteTopicContent({ content, theme, isMobile }) {
  const {
    content: {
      widgets,
    },
  } = relationships(content);

  const contentWidgets = getRealType(content) === 'news-gallery'
    ? widgets.slice(1, widgets.length)
    : widgets;

  const button = theme.controls.button;
  const buttonSize = button.const.sizes.medium;
  const buttonType = button.primary;

  return (
    <Fragment>
      <TopicContent
        content={contentWidgets}
        interitemSpacing={isMobile ? 20 : 30}
        blocks={{
          opinion(block, parentProps) {
            return <Opinion block={block} parentProps={parentProps} />;
          },
          incut(block, parentProps, ctx) {
            return (
              <Incut
                block={block}
                parentProps={parentProps}
                ctx={ctx}
              />
            );
          },
          image(block, parentProps, ctx) {
            const props = { ...parentProps };
            return coreImage(block, props, ctx);
          },
          readMore(block, parentProps) {
            return <ReadMore block={block} parentProps={parentProps} />;
          },
          recipe(block, parentProps) {
            return <Recipe block={block} parentProps={parentProps} />;
          },
          photoGallery(block, parentProps) {
            return <PhotoGallery block={block} parentProps={parentProps} />;
          },
          markdown(block, parentProps, ctx) {
            return (
              <Markdown
                block={block}
                parentProps={parentProps}
                ctx={ctx}
              />
            );
          },
        }}
        mobileAds={mobileAds}
      />
      <style jsx>{`
        :global(.btn._red)
          font ${buttonSize.font}
          padding ${buttonSize.padding}
          border-radius ${button.const.radius}
          color ${buttonType.idle.color}
          background ${buttonType.idle.background}
          border ${buttonType.idle.border}
          &:hover
            color ${buttonType.hover.color}
            background ${buttonType.hover.background}
            border ${buttonType.hover.border}
      `}
      </style>
    </Fragment>
  );
}

SiteTopicContent.propTypes = {
  content: PropTypes.object,
  mobileAds: PropTypes.arrayOf(PropTypes.object),
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
};

export default withBreakpoint(withTheme(SiteTopicContent));
