import withBindProps from 'core/hocs/withBindProps';

import Ad from 'core/components/Ad';
/**
 * Как выяснилось в комментарии (https://goo.gl/qPm9r4) у мобильных баннерах
 * нужно указывать мобильный `siteId` (`begun-auto-pad`).
 */
const commonProps = {
  siteId: '459070184',
  height: 250,
};

export const Fullscreen = withBindProps({
  ...commonProps,
  name: 'Fullscreen',
  blockId: '459083052',
  height: null,
  options: {
    p1: 'btrbt',
    p2: 'etsa',
    pct: 'a',
  },
})(Ad);

export const TopBanner = withBindProps({
  ...commonProps,
  name: 'TopBanner',
  blockId: '459080300',
  options: {
    'p1': 'btrbv',
    'p2': 'fexc',
    'pct': 'a',
  },
})(Ad);


export const Rich = withBindProps({
  ...commonProps,
  name: 'Rich',
  height: null,
  blockId: '459082098',
  options: {
    'p1': 'btrbr',
    'p2': 'ffhi',
    'pct': 'a',
  },
})(Ad);

export const Listing1 = withBindProps({
  ...commonProps,
  name: 'Listing1 (COM)',
  lazy: true,
  blockId: '459082960',
  options: {
    'p1': 'bwuiu',
    'p2': 'emil',
    'pct': 'a',
  },
})(Ad);

export const Listing2 = withBindProps({
  ...commonProps,
  name: 'Listing2 (COM)',
  lazy: true,
  blockId: '459082926',
  options: {
    p1: 'bwuiv',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const Footer = withBindProps({
  ...commonProps,
  name: 'Footer (COM)',
  lazy: true,
  blockId: '459083058',
  options: {
    'p1': 'bwuit',
    'p2': 'emil',
    'pct': 'b',
  },
})(Ad);

export const Content1 = withBindProps({
  ...commonProps,
  name: 'Content1 (COM)',
  lazy: true,
  blockId: '459083212',
  options: {
    'p1': 'bwuip',
    'p2': 'emil',
    'pct': 'a',
  },
})(Ad);

export const Content2 = withBindProps({
  ...commonProps,
  name: 'Content2 (COM)',
  lazy: true,
  blockId: '459083206',
  options: {
    p1: 'bwuiq',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const Content3 = withBindProps({
  ...commonProps,
  name: 'Content3 (COM)',
  lazy: true,
  blockId: '459083204',
  options: {
    p1: 'bwuir',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const Content4 = withBindProps({
  ...commonProps,
  name: 'Content4 (COM)',
  lazy: true,
  blockId: '459083200',
  options: {
    p1: 'bwuis',
    p2: 'emil',
    pct: 'a',
  },
})(Ad);

export const InPage = withBindProps({
  ...commonProps,
  name: 'InPage',
  lazy: true,
  height: 200,
  blockId: '459082968',
  options: {
    'p1': 'btrbx',
    'p2': 'fexd',
    'pct': 'a',
  },
})(Ad);

