import { PureComponent } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import axios from 'core/libs/axios';

import { LocalStorage } from 'core/decorators';

import withTheme from 'core/hocs/withTheme';
import withBreakpoint from 'core/hocs/withBreakpoint';

import SmartImage from 'core/components/SmartImage';
import Loader from 'core/components/Loader';

import cookieImg from './cookie.png';
import styles from './index.styl';

class Prediction extends PureComponent {
  state = {
    data: '',
    loading: false,
  };

  componentDidMount() {
    this.unmounted = false;
    this.getLocalPrediction();
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  getNewPrediction = () => {
    if (this.clicked) return;
    this.setAtomicState({ loading: true });
    axios.get('/prediction')
      .then(response => response.data)
      .then(body => {
        const { prediction } = body;

        const now = new Date();

        const tomorrow = new Date(
          now.getFullYear(),
          now.getMonth(),
          now.getDate() + 1
        );

        LocalStorage.setItem('prediction', JSON.stringify({
          data: prediction,
          expires: tomorrow.getTime(),
        }));

        this.setAtomicState({ data: prediction, loading: false });
      })
      .catch(error => {
        this.setAtomicState({ error, loading: false });
      });
  };

  getLocalPrediction = () => {
    const now = new Date().getTime();

    const prediction = JSON.parse(LocalStorage.getItem('prediction')) || {};
    const { expires, data } = prediction;

    if (now < expires) {
      this.setAtomicState({ data });
    }
  };

  setAtomicState(...args) {
    if (!this.unmounted) {
      this.setState(...args);
    }
  }

  get clicked() {
    const {
      data,
      loading,
      error,
    } = this.state;
    return !!(loading || data || error);
  }

  render() {
    const {
      data,
      loading,
      error,
    } = this.state;

    const {
      theme,
      isMobile,
    } = this.props;

    return (
      <div
        onClick={this.getNewPrediction}
        className={cx(styles.prediction, !this.clicked && styles._withFixedHeight)}
      >
        <style jsx>{`
          .${styles.prediction}
            background ${theme.colors.active1}
          .${styles.data}
          .${styles.loadingText}
          .errorText
            font 700 18px/22px ${theme.fonts.display}
            color ${theme.colors.card}
          .${styles.nextPrediction}
            font 400 14px/18px ${theme.fonts.display}

          .${styles.title}
            font 700 20px/23px ${theme.fonts.display3}
            color ${theme.colors.card}
            :global(.mobile) &
              font 700 14px/16px ${theme.fonts.display3}
        `}</style>
        <div className={styles.title}>Предсказание дня</div>
        <div className={styles.cookie}>
          <SmartImage
            className={styles.image}
            url={cookieImg}
            width={isMobile ? 103 : 170}
            height={isMobile ? 70 : 120}
          />
        </div>
        <div className={styles.content}>
          {data ? (
            <div className={styles.result}>
              <div className={styles.data}>
                {data}
              </div>
              <div className={styles.nextPrediction}>
                Следующее предсказание вы узнаете завтра
              </div>
            </div>
          ) : (
            <div className={styles.result}>
              {loading && (
                <Loader>
                  <span className={styles.loadingText}>
                    Генерируем предсказание...
                  </span>
                </Loader>
              )}
              {error && (
                <span className='errorText'>
                  Произошла досадная ошибка, попробуйте еще раз.
                </span>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}

Prediction.propTypes = {
  /** @ignore */
  theme: PropTypes.object,
  /** @ignore */
  isMobile: PropTypes.bool,
};

const PredictionWithHOCs = withTheme(withBreakpoint(Prediction));
PredictionWithHOCs.displayName = 'Prediction';

export default PredictionWithHOCs;

export { Prediction as StorybookComponent };
