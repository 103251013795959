import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/hocs/withTheme';

import styles from './index.styl';

const ProgressBar = ({
  size,
  lineWidth,
  value,
  theme,
  className,
}) => {
  const center = size / 2;
  const backgroundCircleRadius = (size - lineWidth) / 2;

  const radius = center - lineWidth;
  const lineLength = 2 * Math.PI * radius;
  const progress = (1 - value / 100) * lineLength;

  return (
    <div className={cx(styles.progressBarWrapper, className)}>
      <style jsx>{`
        .${styles.text}
          font bold 36px/40px ${theme.fonts.display}
      `}</style>
      <div className={styles.text}>
        {value}%
      </div>
      <svg
        width={size}
        height={size}
        viewBox={`0 0 ${size} ${size}`}
      >
        <circle
          r={backgroundCircleRadius}
          cx={center}
          cy={center}
          fill='rgba(255, 255, 255, 0.5)'
        />
        <circle
          r={radius}
          cx={center}
          cy={center}
          fill='transparent'
          transform={`rotate(-90 ${center} ${center})`}
          strokeDasharray={lineLength}
          strokeDashoffset={progress}
          stroke={theme.colors.active1}
          strokeWidth={lineWidth}
          strokeLinecap='round'
        />
      </svg>
    </div>
  );
};

ProgressBar.propTypes = {
  size: PropTypes.number.isRequired,
  lineWidth: PropTypes.number,
  value: PropTypes.number,
  className: PropTypes.string,
  theme: PropTypes.object,
};

ProgressBar.defaultProps = {
  lineWidth: 4,
  value: 0,
};

export default withTheme(ProgressBar);
