import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/hocs/withTheme';


function Headline({ children, theme, className }) {
  return (
    <h1 className={cx('headline', className)}>
      <style jsx>{`
        .headline
          font ${theme.texts.headline.font}
          color ${theme.texts.headline.color}
      `}
      </style>
      <style jsx>{`
        .headline
          margin 0 0 25px 0
          padding 0
      `}
      </style>
      {children}
    </h1>
  );
}

Headline.propTypes = {
  /** @ignore */
  className: PropTypes.string,
  /** @ignore */
  theme: PropTypes.object,
};

const HeadlineWithHOCs = withTheme(Headline);
HeadlineWithHOCs.displayName = 'Headline';

export default HeadlineWithHOCs;

export { Headline as StorybookComponent };
