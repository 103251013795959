import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import withBreakpoint from 'core/hocs/withBreakpoint';
import { DESKTOP, MOBILE } from 'core/components/breakpoint/values';
import withTheme from 'core/hocs/withTheme';
import withBindProps from 'core/hocs/withBindProps';


import AuthorPage from 'core/components/AuthorPage';
import H4 from 'core/components/H4';
import PageLoader from 'core/components/Loader/PageLoader';

import breakpointPropTypes from 'core/utils/prop-types/breakpoint';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import {
  authorQuery,
  topicsQuery,
} from 'core/queries/authorPage';

import BasePage from 'site/components/BasePage';
import {
  VERTICAL,
  VERTICAL_MOBILE,
  HORIZONTAL,
  HORIZONTAL_MOBILE,
} from 'site/components/Indents';

import Card2 from 'site/cards/Card2';
import Card3 from 'site/cards/Card3';
import { IndentWrap } from 'site/components/Wrappers';


const breakpointCards = {
  [DESKTOP]: Card2,
  [MOBILE]: Card3,
};

const LIMIT = 15;

function Author(props) {
  const { breakpoint, theme, isMobile } = props;

  const scope = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .title
          color ${theme.colors.primary}!important
      `}</style>
    </scope>
  );

  const verticalSpacing = isMobile ? VERTICAL_MOBILE : VERTICAL;
  const sideSpacing = isMobile ? HORIZONTAL_MOBILE : 0;

  const results = useRequest({ queries: [
    authorQuery(props),
    topicsQuery({
      ...props,
      card: breakpointCards[breakpoint],
      limit: LIMIT,
    }),
  ] });
  const [
    { data: author, isLoading: authorIsLoading },
    { data: topics, isLoading: topicsAreLoading },
  ] = results;

  return (
    <BasePage>
      <IndentWrap
        top={verticalSpacing}
        right={sideSpacing}
        left={sideSpacing}
      >
        {authorIsLoading && <PageLoader />}
        {!authorIsLoading && (
          <>
            <AuthorPage
              author={author}
              topics={topics}
              limit={LIMIT}
              columns={3}
              grid
              card={breakpointCards[breakpoint]}
              interitemSpacing={isMobile ? HORIZONTAL_MOBILE : HORIZONTAL}
              isLoading={topicsAreLoading}
              topicsTitleComponent={withBindProps({ className: scope.wrapClassNames('title') })(H4)}
            />
            <scope.styles />
          </>
        )}
      </IndentWrap>
    </BasePage>
  );
}

Author.propTypes = {
  isMobile: PropTypes.bool,
  theme: PropTypes.object,
  breakpoint: breakpointPropTypes(),
};

export default withTheme(withBreakpoint(Author));
