import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';
import Link from 'core/components/Link';
import Image from 'core/components/Image';

import relationshipsPropTypes, {
  imagePropTypes,
} from 'core/utils/prop-types/relationships';
import resolveRelationships from 'core/utils/relationships';

import Button from 'core/components/Button';

import styles from './index.styl';

const requiredPayloadImports = ['image', 'opinion'];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
  opinion: {},
});

function CardExpert({ content, theme }) {
  if (!content) return null;

  const {
    link,
    headline,
    list_headline: listHeadline,
  } = content.attributes;

  const {
    opinion,
  } = relationships(content);

  const {
    job_title: jobTitle,
    name,
    avatar,
  } = opinion?.author?.attributes || {};

  const {
    versions,
  } = avatar?.attributes || {};

  return (
    <Link
      className={styles.link}
      to={link}
      type='secondary'
    >
      <div className={styles.cardExpert}>
        <style jsx>{`
          .${styles.cardExpert}
            background ${theme.colors.white}
            :global(.mobile) &
              background ${theme.colors.grey3}

          .${styles.jobTitle}
            font-family ${theme.fonts.display}
            color ${theme.colors.grey}

          .${styles.name}
            color ${theme.colors.black}
            font-family ${theme.fonts.display}

          .${styles.headline}
            font-family ${theme.fonts.display}
            color ${theme.colors.black}
            transition ${theme.animations.hover}
        `}</style>
        <div className={styles.headline}>{listHeadline || headline}</div>
        <div className={styles.author}>
          <div className={styles.info}>
            {name && <div className={styles.name}>{name}</div>}
            {jobTitle && <div className={styles.jobTitle}>{jobTitle}</div>}
          </div>
          {versions && (
            <div className={styles.image}>
              <Image
                versions={versions}
                aspectRatio={1}
                width={40}
              />
            </div>
          )}
        </div>
        <div>
          <Button size='small'>Читать статью</Button>
        </div>
      </div>
    </Link>
  );
}

CardExpert.propTypes = {
  /** Контент топика */
  content: relationshipsPropTypes({
    image: imagePropTypes(),
  }),
  /** @ignore */
  theme: PropTypes.object,
};

const Card = withTheme(CardExpert);

Card.displayName = 'CardExpert';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { CardExpert as StorybookComponent };
