import PropTypes from 'prop-types';

import useRequest from 'core/hooks/useRequest';

import withBreakpoint from 'core/hocs/withBreakpoint';
import H1 from 'core/components/H1';
import AuthorsPage from 'core/components/AuthorsPage';

import { rawAuthorsQuery } from 'core/queries/authorsPage';

import BasePage from 'site/components/BasePage';
import { IndentWrap } from 'site/components/Wrappers';
import { VERTICAL, VERTICAL_MOBILE, HORIZONTAL_MOBILE } from 'site/components/Indents';


function Authors(props) {
  const { isMobile } = props;

  const verticalSpacing = isMobile ? VERTICAL_MOBILE : VERTICAL;
  const sideSpacing = isMobile ? HORIZONTAL_MOBILE : 0;

  const { data: rawAuthors, isLoading } = useRequest(rawAuthorsQuery(props));

  return (
    <BasePage>
      <IndentWrap
        top={verticalSpacing}
        left={sideSpacing}
        right={sideSpacing}
      >
        <IndentWrap bottom={verticalSpacing}>
          <H1>Авторы</H1>
        </IndentWrap>
        <AuthorsPage
          rawAuthors={rawAuthors}
          isLoading={isLoading}
        />
      </IndentWrap>
    </BasePage>
  );
}

Authors.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(Authors);
