import { Fragment } from 'react';
import PropTypes from 'prop-types';
import Select, { components } from 'react-select';

import withTheme from 'core/hocs/withTheme';

import styles from './index.styl';

/*
* Переопределяем компоненты Select'а
* Скрываем стрелку и разделитель
*/
const EmptyComponent = () => null;

const CustomSelect = ({ theme, className, prefixIcon, ...restProps }) => {
  const selectStyles = {
    control: controlStyles => ({
      ...controlStyles,
      borderRadius: '16px',
      boxShadow: 'none',
      font: theme.texts.caption1.font,
      minHeight: '36px',
    }),
    option: (optionStyles, optionState) => ({
      ...optionStyles,
      font: theme.texts.caption1.font,
      color: optionState.isFocused ? 'white' : 'black',
    }),
  };

  const selectThemes = selectTheme => ({
    ...selectTheme,
    font: theme.texts.caption1.font,
    colors: {
      ...selectTheme.colors,
      // цвет опций при наведении
      primary25: theme.colors.opacityActive1,
      primary: theme.colors.active1,
      // цвет текста плейсхолдера
      neutral50: theme.colors.placeholder,
    },
  });

  const SelectContainer = ({ ...containerProps }) => (
    <components.SelectContainer {...containerProps} className={className} />
  );

  /*
  * Переопределяем компоненты Select'а
  * Добавляем иконку-префикс в начало Select'а
  */
  const Control = ({ children, ...controlProps }) => (
    <components.Control {...controlProps} className={styles.control}>
      <style jsx>{`
        .${styles.iconWrapper}
          background-image url('${prefixIcon}')
      `}</style>
      {
        prefixIcon && <div className={styles.iconWrapper} />
      }
      {children}
    </components.Control>
  );

  return (
    <Fragment>
      <Select
        {...restProps}
        components={{
          DropdownIndicator: EmptyComponent,
          IndicatorSeparator: EmptyComponent,
          Control,
          SelectContainer,
        }}
        theme={selectThemes}
        styles={selectStyles}
        noOptionsMessage={() => 'Ничего не найдено.'}
        blurInputOnSelect
      />
    </Fragment>
  );
};

CustomSelect.propTypes = {
  /** @ignore */
  theme: PropTypes.object,
  /** @ignore */
  className: PropTypes.string,
  /** @ignore */
  styles: PropTypes.object,
  /** Иконка-префикс в начале Select'a */
  prefixIcon: PropTypes.string,
  /** Массив элементов для списка выбора */
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  /** Плейсхолдер в поле ввода */
  placeholder: PropTypes.string,
  /** Значение, которое будет установлено по умолчанию */
  value: PropTypes.shape({
    label: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
  }),
  /**
   * Коллбек обработки события onChange.
   * Обязателен при указании value, для
   * возможности его последующего изменения
   */
  onChange: PropTypes.func.isRequired,
};

const CustomSelectWithHOCs = withTheme(CustomSelect);
CustomSelectWithHOCs.displayName = 'CustomSelect';

export default CustomSelectWithHOCs;

export { CustomSelect as StorybookComponent };
