import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';
import cx from 'classnames';

import withThemeSwitcher from 'core/hocs/withThemeSwitcher';
import withTheme from 'core/hocs/withTheme';
import withBindProps from 'core/hocs/withBindProps';

import Link from 'core/components/Link';
import SmartImage from 'core/components/SmartImage';
import AdvTooltip from 'core/components/AdvTooltip';

import relationshipsPropTypes, {
  imagePropTypes,
} from 'core/utils/prop-types/relationships';
import resolveRelationships from 'core/utils/relationships';

import styles from './index.styl';

const requiredPayloadImports = ['image'];

const requiredPayloadFields = [
  'headline',
  'list_headline',
  'link',
  'published_at',
  'topic_type',
  'ad_label',
  'link_with_announce_erid',
  'advertiser_name',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});

function splitByFirstСolon(str) {
  const colonIndex = str.indexOf(':');

  if (colonIndex === -1) {
    return [str, ''];
  }
  return [str.substring(0, colonIndex + 1), str.substring(colonIndex + 1)];
}

function Card4(props) {
  const {
    content,
    theme,
    highPriorityImage,
    location,
    position,
    size,
  } = props;

  const {
    headline,
    list_headline: listHeadline,
    link,
    ad_label: adLabel,
    link_with_announce_erid: linkWithAnnounceErid,
    advertiser_name: advertiserName,
  } = content.attributes;

  const {
    image: {
      versions: {
        original: cover,
      },
    },
  } = relationships(content);

  const imageProps = {
    ...highPriorityImage && { fetchPriority: 'high', instant: true },
  };

  const isMainPage = location.pathname === '/';
  const shouldShowAdvTooltip = !!(isMainPage && position === 2 && linkWithAnnounceErid && advertiserName && adLabel);
  const topicLink = shouldShowAdvTooltip ? linkWithAnnounceErid : link;
  const title = listHeadline || headline;
  const headlineSplited = splitByFirstСolon(title);

  return (
    <div className={cx(styles.card, styles[`_size_${size}`])}>
      <style jsx>{`
        .${styles.title}
          font-family ${theme.fonts.display3}
          color ${theme.colors.primary}

        .${styles.image}
          &:before
          &:after
            transition opacity ${theme.animations.hover}

        .${styles.subtitle}
          font-family ${theme.fonts.display}
          color ${theme.colors.grey1}
      `}</style>
      <Link
        to={topicLink}
        type='secondary'
        className={styles.link}
      >
        <div className={styles.image}>
          <SmartImage
            src={cover}
            maxWidth={416}
            aspectRatio={0.667}
            {...imageProps}
          />
        </div>
        <div className={styles.content}>
          <span className={styles.title}>
            {headlineSplited[0]}
          </span>
          {headlineSplited[1].length > 0 &&
            <span className={styles.subtitle}>
              {headlineSplited[1]}
            </span>
          }
        </div>
      </Link>
      {shouldShowAdvTooltip && (
        <AdvTooltip
          text={advertiserName}
          adLabel={adLabel}
          position='bottom'
        />
      )}
    </div>
  );
}

Card4.propTypes = {
  /** Контент топика */
  content: relationshipsPropTypes({
    image: imagePropTypes(),
  }),
  /** @ignore */
  theme: PropTypes.object,
  /**
  * Использование fetchPriority для загрузки картинки
  */
  highPriorityImage: PropTypes.bool,
  /** @ignore */
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }),
  position: PropTypes.number,
  size: PropTypes.string,
};

Card4.defaultProps = {
  size: 'm',
};

const Card = withThemeSwitcher('dark')(withTheme(withRouter(Card4)));

Card.displayName = 'Card4';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export const Card4S = withBindProps({ size: 's' })(Card);

export default Card;

export { Card4 as StorybookComponent };
