import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import resolveRelationships from 'core/utils/relationships';

import withBindProps from 'core/hocs/withBindProps';
import withCard from 'core/hocs/withCard';
import Link from 'core/components/Link';
import Image from 'core/components/Image';

import styles from './index.styl';

const requiredPayloadImports = [];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
];

const relationships = resolveRelationships(['image'], {}, {
  image: {
    versions: {},
  },
});

function Card1(props) {
  const {
    content,
    position,
    theme,
    showImage,
    showPosition,
  } = props;

  const {
    attributes: {
      link,
      headline,
      list_headline: listHeadline,
    },
  } = content;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  const {
    remote_image: remoteImage,
  } = content.extra || {};

  return (
    <Link
      to={link}
      type='secondary'
      className={cx(styles.link, showPosition && styles._showPosition)}
    >
      <style jsx>{`
        .${styles.position}
          font 700 24px/28px ${theme.fonts.display3}
          color ${theme.colors.active1}

        .${styles.title}
          font 400 16px/19px ${theme.fonts.display}
          color ${theme.colors.black}
      `}</style>
      <div className={styles.content}>
        { showPosition && (
          <div className={styles.position}>{position + 1}</div>
        )}
        { showImage && (cover || remoteImage) && (
          <div className={styles.image}>
            <Image
              {...cover && { src: cover }}
              {...cover && { previewSrc: previewCover }}
              {...remoteImage && { url: remoteImage }}
              width={100}
              height={100}
            />
          </div>
        )}
        <div className={styles.title}>
          {listHeadline || headline}
        </div>
      </div>
    </Link>
  );
}

Card1.propTypes = {
  /** Порядковый номер карточки */
  position: PropTypes.number,
  /** Контент топика */
  content: PropTypes.object,
  /** @ignore */
  theme: PropTypes.object,

  showImage: PropTypes.bool,
  showPosition: PropTypes.bool,
};

Card1.defaultProps = {
  showImage: true,
};

const Card = withCard(Card1);

export const Card1Type1 = withBindProps({ showPosition: true, showImage: false })(Card);

Card.displayName = 'Card1';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { Card1 as StorybookComponent };
