import PropTypes from 'prop-types';

import Link from 'core/components/Link';
import withTheme from 'core/hocs/withTheme';
import { resolveScopedStyles } from 'core/utils/styled-jsx';
import resolveRelationships from 'core/utils/relationships';
import styles from './index.styl';

const colors = [
  'FF4B24',
  'FF7B3B',
  'FFA529',
  'F7B815',
  'F7C515',
  'F7D215',
  'EFCF24',
  'E4DD22',
  'C3D122',
  '99D122',
  '22D13E',
  '0BBB86',
];

const relationships = resolveRelationships(['name_compatibility'], {});

const CompatibilityPair = ({ pair, theme, sex }) => {
  const scoped = resolveScopedStyles((
    <scope>
      <style jsx>{`
        .${styles.link}
        .${styles.link}:visited
          color ${theme.colors.primary}
          &:hover
            color ${theme.colors.active1}
      `}
      </style>
    </scope>
  ));

  const {
    name_compatibility: nameCompatibility,
    name_compatibility: {
      overall,
    },
  } = relationships(pair);

  const color = '#' + colors[Math.round(overall * (colors.length - 1) / 100)];

  return (
    <li className={styles.item}>
      <style jsx>{`
        .${styles.item}
          font 18px ${theme.fonts.nuance}
        .${styles.percentage}
          left ${overall + '%'}
      `}</style>
      <Link
        style={{ textDecoration: 'none' }}
        className={scoped.wrapClassNames(styles.link)}
        to={pair.attributes.link}
      >
        {nameCompatibility[sex + '_name']}
      </Link>
      <div className={styles.barWrapper}>
        <div
          style={{ width: overall + '%', background: color }}
          className={styles.bar}
        />
        <span className={styles.percentage}>{overall}%</span>
      </div>
      <scoped.styles />
    </li>
  );
};

CompatibilityPair.propTypes = {
  pair: PropTypes.object.isRequired,
  sex: PropTypes.oneOf(['male', 'female']),
  theme: PropTypes.object,
};

export default withTheme(CompatibilityPair);
