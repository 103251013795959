import { Fragment } from 'react';
import PropTypes from 'prop-types';

import { resolveScopedStyles } from 'core/utils/styled-jsx';
import withTheme from 'core/hocs/withTheme';
import Link from 'core/components/Link';

import { VerticalIndent } from 'site/components/Indents';
import { SOVMESTIMOST_LINK } from 'site/constants';

import styles from './index.styl';

const NamesList = ({ items, theme, title, sex }) => {
  const scoped = resolveScopedStyles((
    <scope>
      <style jsx>{`
        .itemLink
        .itemLink:visited
          color ${theme.colors.primary}
          &:hover
            color ${theme.colors.active1}
      `}
      </style>
    </scope>
  ));

  return (
    <Fragment>
      <style jsx>{`
        .title
          font bold 24px/1.2 ${theme.fonts.nuance}
          color ${theme.colors.primary}
        .${styles.namesList} > li
          font 18px/1.2 ${theme.fonts.nuance}
      `}</style>
      <VerticalIndent indent={40} />
      <div className='title'>{title}</div>
      <VerticalIndent indent={20} />
      <ul className={styles.namesList}>
        {items.map((name, i) => (
          <li key={i} className={styles.namesListItem}>
            <Link
              className={scoped.wrapClassNames('itemLink')}
              style={{ textDecoration: 'none' }}
              to={`${SOVMESTIMOST_LINK}/${sex}/${name.translit}`}
            >
              {name.value}
            </Link>
          </li>
        ))}
      </ul>
      <scoped.styles />
    </Fragment>
  );
};

NamesList.propTypes = {
  title: PropTypes.string.isRequired,
  items: PropTypes.array.isRequired,
  sex: PropTypes.string.isRequired,
  theme: PropTypes.object,
};

export default withTheme(NamesList);
