import color from 'core/libs/color';

import withBindProps from 'core/hocs/withBindProps';

import Logo from 'site/components/Logo';

const colors = {
  content: '#32292F',
  active1: '#3037eb',
  active2: '#f6c5af',
  accent: '#ff3e56',
  error: '#cc3145',
  yellow1: '#fff3ca',
  yellow2: '#f4d56e',
  grey1: '#c1c1c1',
  get input() {
    return this.content;
  },
  primary: '#fff',
  get hint() {
    return color(this.primary).alpha(0.5).string();
  },
  get placeholder() {
    return color(this.primary).alpha(0.2).string();
  },
  get divider() {
    return color(this.primary).alpha(0.1).string();
  },
  get cardHover() {
    return this.primary;
  },
  get opacityActive1() {
    return color(this.active1).alpha(0.5).string();
  },
  get theme() {
    return this.active1;
  },
};

export default {
  colors,
  controls: {
    button: {
      primary: {
        idle: {
          background: colors.active1,
          color: colors.primary,
        },
        hover: {
          background: colors.active1,
          color: colors.primary,
        },
      },
    },
    shapka: {
      logo: {
        Icon: withBindProps({ type: 'light' })(Logo),
      },
      background: colors.content,
      stickyBackground: colors.content,
      backgroundOpened: colors.content,
    },
    login: {
      popup: {
        content: {
          color: colors.primary,
        },
      },
    },
  },
};
