import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/hocs/withTheme';

import styles from './index.styl';

function TitleCardM(props) {
  const {
    fontWeight,
    disableColor,
    disableTransition,
    theme,
    children,
  } = props;

  return (
    <div className={cx(styles.title, disableColor && '_disabledColor', disableTransition && '_disabledTransition')}>
      <style jsx>{`
        .${styles.title}
          font-family ${theme.fonts.display}
          font-weight ${fontWeight}
          color ${theme.colors.primary}
          &:not(._disabledColor)
            &:not(._disabledTransition)
              transition color ${theme.animations.hover}
            :global(a):hover &
              color ${theme.colors.active1}
      `}
      </style>
      {children}
    </div>
  );
}

TitleCardM.propTypes = {
  fontWeight: PropTypes.number,
  disableColor: PropTypes.bool,
  disableTransition: PropTypes.bool,
  theme: PropTypes.object,
};

TitleCardM.defaultProps = {
  fontWeight: 400,
};

export default withTheme(TitleCardM);
