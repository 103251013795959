import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { LocalStorage } from 'core/decorators';
import Link from 'core/components/Link';

import withTheme from 'core/hocs/withTheme';
import withBreakpoint from 'core/hocs/withBreakpoint';

import Close from './icons/Close';
import Telegram from './icons/Telegram';

import styles from './index.styl';

const link = 'https://t.me/pro_passion';

function TelegramBanner(props) {
  const {
    theme,
    isMobile,
  } = props;

  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const lastClosed = LocalStorage.getItem('bannerLastClosed');
    const visitCount = parseInt(LocalStorage.getItem('visitCount'), 10) || 0;

    if (lastClosed) {
      const lastClosedDate = new Date(lastClosed);
      const currentDate = new Date();
      const timeDiff = currentDate - lastClosedDate;
      const monthInMs = 30 * 24 * 60 * 60 * 1000;

      if (visitCount >= 3 || timeDiff >= monthInMs) {
        setIsVisible(true);
        LocalStorage.removeItem('visitCount');
        LocalStorage.removeItem('bannerLastClosed');
      } else {
        LocalStorage.setItem('visitCount', visitCount + 1);
      }
    } else {
      setIsVisible(true);
    }
  }, []);

  const handleClose = () => {
    setIsVisible(false);
    LocalStorage.setItem('bannerLastClosed', new Date().toISOString());
    LocalStorage.setItem('visitCount', 1);
  };

  if (!isVisible) return null;

  const title = isMobile ? 'Страсти в Telegram' : 'Подписывайся на Страсти в Telegram';
  const subtitle = isMobile ? 'Новости, которые заводят' : '«Страсти» – это новости, которые заводят';

  return (
    <div className={styles.banner}>
      <style jsx>{`
        .${styles.container}
          background-color ${theme.colors.black}

        .${styles.content}
          color ${theme.colors.content}
          &:hover
            color ${theme.colors.active1}

        .title
          font 900 13px/15px ${theme.fonts.display3}

        .subtitle
          font 400 14px/17px ${theme.fonts.display}
      `}</style>
      <div className={styles.container}>
        <Link
          to={link}
          type='primary'
          className={styles.link}
        >
          <div className={styles.content}>
            <Telegram className={styles.icon} />
            <div className={styles.text}>
              <div className='title'>{title}</div>
              <div className='subtitle'>{subtitle}</div>
            </div>
          </div>
        </Link>
        <button
          className={styles.button}
          onClick={handleClose}
        >
          <Close width={24} height={24} />
        </button>
      </div>
    </div>
  );
}

TelegramBanner.propTypes = {
  theme: PropTypes.object,
  isMobile: PropTypes.bool,
  match: PropTypes.object,
};

export default withTheme(withBreakpoint(TelegramBanner));
