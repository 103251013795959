import React from 'react';
import PropTypes from 'prop-types';

import withBindProps from 'core/hocs/withBindProps';
import withCard from 'core/hocs/withCard';
import Link from 'core/components/Link';
import Image from 'core/components/Image';

import relationshipsPropTypes, {
  imagePropTypes,
} from 'core/utils/prop-types/relationships';
import themePropTypes from 'core/utils/prop-types/theme';
import resolveRelationships from 'core/utils/relationships';
import cutDown from 'core/utils/cut-down';

import TitleCard from 'site/components/TitleCardM';

import mapTypes from './types';

import styles from './index.styl';

const requiredPayloadImports = ['image'];

const requiredPayloadFields = [
  'link',
  'headline',
  'list_headline',
  'topic_type',
  'announce',
];

const relationships = resolveRelationships(requiredPayloadImports, {}, {
  image: {
    versions: {},
  },
});

function Card2(props) {
  const {
    content,
    theme,
    type,
    isMobile,
  } = props;

  const {
    showAnnounce,
  } = mapTypes[type] || {};

  const {
    attributes: {
      link,
      headline,
      list_headline: listHeadline,
      topic_type: topicType,
      announce,
    },
  } = content;

  const {
    image: {
      versions: {
        original: cover,
        thumbnail: previewCover,
      },
    },
  } = relationships(content);

  const {
    link_attrs: linkAttrs,
    remote_image: remoteImage,
  } = content.extra || {};

  const {
    icons: {
      Video,
      Photo,
    },
  } = theme;

  const mapIconByType = {
    video: Video,
    gallery: Photo,
  };

  const Icon = mapIconByType[topicType];

  return (
    <Link
      to={link}
      type='secondary'
      {...linkAttrs && {
        innerRef: node => {
          if (node) {
            linkAttrs.forEach(({ name, value }) => node.setAttribute(name, value));
          }
        },
      }}
    >
      <style jsx>{`
        .announce
          color ${theme.colors.primary}
          font-size ${isMobile ? '14px' : '16px'}
      `}</style>

      <div className={styles.image}>
        <Image
          {...cover && { src: cover }}
          {...cover && { previewSrc: previewCover }}
          {...remoteImage && { url: remoteImage }}
          maxWidth={isMobile ? 600 : 285}
          aspectRatio={275 / 156}
        />
        <div className={styles.icons}>
          { Icon && (
            <Icon
              height={30}
              width={30}
              className={styles.icon}
            />
          )}
        </div>
      </div>
      <TitleCard>{listHeadline || headline}</TitleCard>
      {showAnnounce && announce && (
        <div className={`${styles.announce} announce`}>
          { cutDown(announce) }
        </div>
      )}
    </Link>
  );
}

Card2.propTypes = {
  /** Контент топика */
  content: relationshipsPropTypes({
    image: imagePropTypes(),
  }),
  /** @ignore */
  isMobile: PropTypes.bool,
  /** Тип карточки */
  type: PropTypes.oneOf([1]),
  /** @ignore */
  theme: themePropTypes(`
    {
      icons: {
        Video,
        Photo,
      },
    },
  `),
};

const Card = withCard(Card2);

export const Card2Type1 = withBindProps({ type: 1 })(Card);

Card.displayName = 'Card2';
Card.requiredPayloadImports = requiredPayloadImports;
Card.requiredPayloadFields = requiredPayloadFields;

export default Card;

export { Card2 as StorybookComponent };
