import PropTypes from 'prop-types';

import BreadcrumbsCore from 'core/components/Breadcrumbs';
import withTheme from 'core/hocs/withTheme';


function Breadcrumbs(props) {
  const {
    theme,
    ...otherProps
  } = props;

  return (
    <div className='breadcrumbs'>
      <style jsx>{`
        .breadcrumbs
          :global(li)
            font italic 16px/1.25 ${theme.fonts.display}
            color #bbb
          :global(a)
            font italic 16px/1.25 ${theme.fonts.display}
            color #bbb
            &:visited
              color #bbb
            &:hover
              color #bbb
              border-bottom 1px solid ${theme.colors.active1}
      `}
      </style>
      <BreadcrumbsCore {...otherProps} />
    </div>
  );
}

Breadcrumbs.propTypes = {
  theme: PropTypes.object,
};

export default withTheme(Breadcrumbs);
