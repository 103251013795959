import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import withTheme from 'core/hocs/withTheme';


function Quote(props) {
  const {
    children,
    theme: {
      texts: {
        quote: quoteAtoms,
      },
    },
  } = props;

  return (
    <Fragment>
      <style jsx>{`
        .quote
          font ${quoteAtoms.font}
          color ${quoteAtoms.color}
          margin 0
      `}</style>

      <blockquote className='quote'>{children}</blockquote>
    </Fragment>
  );
}

Quote.propTypes = {
  /** @ignore */
  theme: PropTypes.object,
};

const QuoteWithHOCs = withTheme(Quote);
QuoteWithHOCs.displayName = 'Quote';

export default QuoteWithHOCs;

export { Quote as StorybookComponent };
