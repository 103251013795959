import { Component } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import withTheme from 'core/hocs/withTheme';
import { Desktop } from 'core/components/breakpoint';
import Button from 'core/components/Button';
import Link from 'core/components/Link';
import EmptyWrapper from 'core/components/EmptyWrapper';

import { SOVMESTIMOST_LINK } from 'site/constants';

import Select from 'site/components/Select';

import MaleIcon from './images/male.png';
import FemaleIcon from './images/female.png';

import styles from './index.styl';

import {
  femaleNames,
  maleNames,
} from './data';

const title = 'Проверьте совместимость имен в вашей паре';
const description = 'Узнайте, партнер с каким именем подходит вам для брака, любви и отношений';

class CompatibilityWidget extends Component {
  state = {
    femaleSelectValue: null,
    maleSelectValue: null,
  };

  handleSelectChange = (value, event) => {
    const { name } = event;
    this.setState({ [name]: value });
  };

  render() {
    const { theme, side } = this.props;
    const { maleSelectValue, femaleSelectValue } = this.state;

    const widgetHead = (
      <div className={styles.head}>
        <style jsx>{`
          .${styles.title}
            font bold 26px/29px ${theme.fonts.display}

          .${styles.description}
            font 16px/20px ${theme.fonts.nuance}
        `}</style>
        <div className={styles.title}>
          {title}
        </div>
        <div className={styles.imageWrapper} />
        <Desktop>
          {!side && (
            <div className={styles.description}>
              {description}
            </div>
          )}
        </Desktop>
      </div>
    );

    const isBothNamesSelected = femaleSelectValue && maleSelectValue;
    const Wrapper = isBothNamesSelected ? Link : EmptyWrapper;

    return (
      <div className={cx(
        styles.compatibilityWidgetWrapper,
        side && styles.side)}
      >
        {
          side ? (
            <Link
              to={SOVMESTIMOST_LINK}
              style={{ textDecoration: 'none' }}
            >
              {widgetHead}
            </Link>
          ) : widgetHead
        }
        <div className={styles.bottom}>
          <Select
            name='femaleSelectValue'
            instanceId='female-select'
            options={femaleNames}
            value={femaleSelectValue}
            onChange={this.handleSelectChange}
            className={styles.select}
            placeholder='Женские имена'
            prefixIcon={FemaleIcon}
          />
          <Select
            name='maleSelectValue'
            instanceId='male-select'
            options={maleNames}
            value={maleSelectValue}
            onChange={this.handleSelectChange}
            className={styles.select}
            placeholder='Мужские имена'
            prefixIcon={MaleIcon}
          />
          <div className={styles.button}>
            <Wrapper to={isBothNamesSelected ? `${SOVMESTIMOST_LINK}/${femaleSelectValue.translit}-i-${maleSelectValue.translit}.htm` : ''}>
              <Button disabled={!isBothNamesSelected}>Проверить</Button>
            </Wrapper>
          </div>
        </div>
      </div>
    );
  }
}

CompatibilityWidget.propTypes = {
  /** Флаг, переключащий виджет в вертикальное положение */
  side: PropTypes.bool,
  /** @ignore */
  theme: PropTypes.object,
};

const CompatibilityWidgetWithHOCs = withTheme(CompatibilityWidget);
CompatibilityWidgetWithHOCs.displayName = 'CompatibilityWidget';

export default CompatibilityWidgetWithHOCs;

export { CompatibilityWidget as StorybookComponent };
