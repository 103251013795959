import PropTypes from 'prop-types';
import cx from 'classnames';

import modelPropTypes, { topicAttributes } from 'core/utils/prop-types/model';
import { resolveScopedStyles } from 'core/utils/styled-jsx';

import withTheme from 'core/hocs/withTheme';
import withTopic from 'core/hocs/withTopic';
import PremiumTopicHeader from 'core/components/PremiumTopicHeader';
import MarkdownWrapper from 'core/components/MarkdownWrapper';

import SocialShare from 'site/components/SocialShare';

import styles from './index.styl';


function TopicHeaderPremium(props) {
  const {
    topic: {
      content,
      infinityIndex,
    },
    theme: {
      controls: {
        topicHeaderTexts: atoms,
        topicContent: topicContentAtoms,
      },
    },
  } = props;

  const {
    attributes: {
      announce,
    },
  } = content;

  const isPremiumInInfinity = infinityIndex !== 0;

  const scoped = resolveScopedStyles(
    <scope>
      <style jsx>{`
        .${styles.lead}
          font ${atoms.lead.font}
          color ${atoms.lead.color}
          letter-spacing ${atoms.lead.letterSpacing || 0}
      `}</style>
    </scope>
  );

  return (
    <>
      <style jsx>{`
        .${styles.wrapper}
          max-width ${topicContentAtoms.widgetsMaxWidth}px
      `}</style>
      {isPremiumInInfinity && (
        <div className={styles.premiumTopicHeader}>
          <PremiumTopicHeader
            content={content}
            isPremiumInInfinity
          />
        </div>
      )}
      <div className={cx(styles.wrapper, isPremiumInInfinity && styles._premiumInInfinity)}>
        {!!announce && (
          <MarkdownWrapper inline className={scoped.wrapClassNames(styles.lead)}>
            {announce}
          </MarkdownWrapper>
        )}
        <SocialShare />
      </div>
      <scoped.styles />
    </>
  );
}

TopicHeaderPremium.propTypes = {
  topic: PropTypes.shape({
    content: modelPropTypes(topicAttributes).isRequired,
    infinityIndex: PropTypes.number,
  }),
  theme: PropTypes.object,
};

export default withTopic(withTheme(TopicHeaderPremium));
